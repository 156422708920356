import ContainerMaterial from "@material-ui/core/Container";
import React, { useState, useEffect, useCallback } from "react";
import { Flex, Image } from "rebass";
import "./styles.css";

import { SERVER_URL, ML_URL } from "../config/config";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as actions from "../redux/actions";

import { makeStyles } from "@material-ui/core/styles";
import CardWelcomeView from "./CardView/CardWelcomeView";
import { Container, Divider, Header } from "semantic-ui-react";

import { Icon, Button, Label } from "semantic-ui-react";

import {
  Grommet as GrommetIcon,
  FacebookOption,
  Instagram,
  Twitter,
} from "grommet-icons";

import { Anchor, Box, Footer, grommet, Grommet, Main, Text } from "grommet";
import Typography from "@material-ui/core/Typography";

import {
  Router,
  Route,
  IndexRoute,
  Link,
  IndexLink,
  browserHistory,
  hashHistory,
  BrowserRouter,
  Redirect,
  withRouter,
} from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  coffee,
  apple,
  faCheckSquare,
  faHome,
} from "@fortawesome/free-solid-svg-icons";

import { useTransition, animated } from "react-spring";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const Media = () => (
  <Box direction="row" gap="xxsmall" justify="center">
    <Anchor
      a11yTitle="Share feedback on Github"
      href="https://www.instagram.com/"
      icon={<Instagram color="brand" />}
    />
    <Anchor
      a11yTitle="Chat with us on Slack"
      href="https://www.facebook.com/"
      icon={<FacebookOption color="brand" />}
    />
    <Anchor
      a11yTitle="Follow us on Twitter"
      href="https://twitter.com/"
      icon={<Twitter color="brand" />}
    />
  </Box>
);

function Welcome(props) {
  const classes = useStyles();

  useEffect(() => {
    if (
      props.authUser === null &&
      props.location.pathname.includes("/welcome") === true
    ) {
      // props.history.push("/welcome");
    } else {
      if (props.authUser?.status === "fail") {
        // props.history.push("/welcome");
        if (props.location.pathname.includes("/welcome") !== true) {
          props.history.push("/welcome");
        }
      } else {
        // props.history.push("/main");
        props.history.push("/");
      }
    }
  });

  return (
    <div>
      <section
        className="section is-primary is-medium"
        // style={{ backgroundColor: "#EFF0F2" }}
      >
        <ContainerMaterial
          // maxWidth="xm"
          component="main"
          className={classes.heroContent}
        >
          {/* <Typography
            variant="h5"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            polypage.ai
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            component="p"
          >
            Meet someone with knowledge.

          </Typography> */}

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
          >
            <Image
              src="https://images.unsplash.com/photo-1451226428352-cf66bf8a0317?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2674&q=80"
              sx={{
                width: ["100%", "50%"],
                height: ["100%", "70%"],
                maxWidth: 1000,
                borderRadius: 8,
              }}
              onClick={() =>
                (window.location.href =
                  SERVER_URL + "/users/auth/google?web=true")
              }
            />
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
          >
            <Button
              style={{ backgroundColor: "#EFE6DF", color: "black" }}
              size="large"
              onClick={() =>
                (window.location.href =
                  SERVER_URL + "/users/auth/google?web=true")
              }
            >
              <Icon name="terminal" />
              Login in polypage.ai
            </Button>
          </div>
        </ContainerMaterial>
      </section>

      {/* <div
        className="simple-trans-main"
        onClick={onClick}
        style={{ height: "10%", width: "10" }}
      >
        {transitions.map(({ item, props, key }) => {
          const Page = pages[item];
          return <Page key={key} style={props} />;
        })}
      </div> */}

      <Container style={{ marginTop: 20 }}>
        {/* <Box align="center" height="small" width="large"> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img style={{ width: 1000, height: 400 }} src={require("../1.png")} />
        </div> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img style={{ width: 1000, height: 400 }} src={require("../2.png")} />
        </div> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            style={{ width: 1000, height: 1000 }}
            src={require("../3.png")}
          />
        </div> */}
      </Container>
      <Footer justify="center" pad="small">
        <Text textAlign="center" size="small">
          © 2021 Copyright polypage.ai
        </Text>
      </Footer>
    </div>
  );
}

export default compose(
  withRouter,
  connect(
    (state) => state,
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )
)(Welcome);
