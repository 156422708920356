import { mergeMap } from "rxjs/operators";

import { ofType } from "redux-observable";
import * as ActionTypes from "../constants/ActionTypes";
import * as actions from "../actions";
import { ajax } from "rxjs/ajax";
import token from "../../API/AjaxGetToken";
import { SERVER_URL, ML_URL } from "../../config/config";

export const exampleEpic = (action$) =>
  action$.pipe(
    ofType(ActionTypes.GET_USER),
    mergeMap((action) =>
      ajax
        .getJSON(
          SERVER_URL + "/userstatus/myroom/" + action.id,
          Object.assign({}, token())
        )
        .pipe(mergeMap((response) => [actions.setUser(response)]))
    )
  );

export default exampleEpic;
