const SERVER_URL = "https://api.polypage.ai";
// const SERVER_URL = "http://localhost:9000";

// const ML_URL = "https://polypage.io";
// const ML_URL = "http://localhost:8000";
// const ML_URL = "https://polypage.ai";
const ML_URL = "https://contenter.dev";

// const ML_URL = "http://175.208.175.249:8000";

export { SERVER_URL, ML_URL };
