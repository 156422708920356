import React, { useState, useEffect, StyleSheet, useMemo } from "react";

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { Slate, Editable, withReact } from "slate-react";

import {
  Container,
  Divider,
  Header,
  Button,
  Icon,
  Card,
  Search,
  Segment,
  Modal,
  Label,
  Loader,
  Dimmer,
  Input,
} from "semantic-ui-react";

import { SERVER_URL, ML_URL } from "../../config/config";
import axios from "axios";

import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";

// var initialText = "";
// var initial_mongodb_id = "";

function TranslationView(props) {
  const initialValue = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  const [valueSlate, setValueSlate] = useState(initialValue);
  const [isShow, setIsShow] = useState(false);
  const [text, setText] = useState("");
  const [initial_mongodb_id, setInitial_mongodb_id] = useState("");
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const [initialText, setInitialText] = useState([]);

  async function fetchData() {
    axios
      .post(SERVER_URL + "/api/translations/find_translate", {
        userId: props?.userId,
        textOriginalEnglish: props?.text,
      })
      .then((res) => {
        console.log(res.data?.textTranslateKorean);
        console.log(res.data._id);

        setInitial_mongodb_id(res.data._id);
        setInitialText(res.data?.textTranslateKorean);

        if (res.data?.textTranslateKorean) {
          setValueSlate(res.data?.textTranslateKorean);
        }

        setIsShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();

    return () => {
      // cleanup
    };
  }, []);

  return (
    <div>
      <Slate
        editor={editor}
        value={valueSlate}
        onChange={(valueSlate) => {
          console.log(valueSlate);
          setValueSlate(valueSlate);

          if (JSON.stringify(initialText) === JSON.stringify(valueSlate)) {
            setIsShow(false);
          } else {
            setIsShow(true);
          }
        }}
      >
        <Editable
          style={{
            flex: 1,
            width: "100%",
            fontSize: 15,
            padding: 10,
            lineHeight: 1.4,
            borderRadius: 4,
            borderWidth: 0,
            // borderColor: "#0093D9",
            borderStyle: "solid",
            // backgroundColor: "#FFFFFF",
            backgroundColor: "lightgrey",
          }}
          placeholder="Enter translate..."
        />
      </Slate>
      {isShow ? (
        <Button
          style={{ marginTop: 10 }}
          positive
          onClick={() => {
            if (initial_mongodb_id) {
              axios
                .put(
                  SERVER_URL + "/api/translations" + "/" + initial_mongodb_id,
                  {
                    userId: props?.userId,
                    textTranslateKorean: valueSlate,
                    textOriginalEnglish: props?.text,
                  }
                )
                .then((res) => {
                  console.log(res.data);
                  fetchData();
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              axios
                .post(SERVER_URL + "/api/translations", {
                  userId: props?.userId,
                  textTranslateKorean: valueSlate,
                  textOriginalEnglish: props?.text,
                })
                .then((res) => {
                  console.log(res.data);
                  fetchData();
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }}
        >
          Save
        </Button>
      ) : null}
    </div>
  );
}

export default TranslationView;
