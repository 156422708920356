import * as ActionTypes from "../constants/ActionTypes";

export default function auth(state = null, action = {}) {
  switch (action.type) {
    case ActionTypes.GET_BOOK_LIST:
      return null;

    case ActionTypes.SET_BOOK_LIST:
      return action.bookList;

    default:
      return state;
  }
}
