import * as ActionTypes from "../constants/ActionTypes";

export function getDevList() {
  return { type: ActionTypes.GET_DEV_LIST };
}

export function setDevList(devList) {
  return { type: ActionTypes.SET_DEV_LIST, devList };
}

export function getDevOne(id) {
  return { type: ActionTypes.GET_DEV_ONE, id };
}

export function setDevOne(devOne) {
  return { type: ActionTypes.SET_DEV_ONE, devOne };
}

export function postDevRoom(post) {
  return { type: ActionTypes.POST_DEV_ROOM, post };
}

export function postDevRoomStatus(status) {
  return { type: ActionTypes.POST_DEV_ROOM_STATUS, status };
}
