import React, { useState, useEffect, StyleSheet } from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { FileUploader } from "baseui/file-uploader";
// import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
// import {userJsonRecoil} from '../SignIn/api/state';
import axios from "axios";
import { Document, Outline, Page } from "react-pdf/dist/esm/entry.webpack";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import * as actions from "../../redux/actions";

import { Slider } from "baseui/slider";

import {
  Container,
  Divider,
  Header,
  Button,
  Icon,
  Card,
  Search,
  Segment,
  Modal,
  Label,
  Loader,
  Dimmer,
  Input,
} from "semantic-ui-react";

import { Datepicker } from "baseui/datepicker";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  // ModalButton,
} from "baseui/modal";

import { useLocation } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import {
  Router,
  Route,
  IndexRoute,
  Link,
  IndexLink,
  browserHistory,
  hashHistory,
  BrowserRouter,
  Redirect,
  withRouter,
} from "react-router-dom";

import TextareaAutosize from "react-textarea-autosize";
import { SERVER_URL, ML_URL } from "../../config/config";

function MainView(props) {
  const [originalText, setOriginalText] = useState("");

  const width = useWindowWidth();

  const [pdfText, setPdfText] = useState("");

  const [page, setPage] = useState(0);

  const [totalPage, setTotalPage] = useState(100);
  const [value, setValue] = React.useState([0]);

  const [errorMessage, setErrorMessage] = React.useState("");

  var fileName = "Chris.pdf";

  const [requestText, setRequestText] = useState([]);

  const [translatedText, setTranslatedText] = useState(new Map());

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  console.log(props?.authUser?.profileId);

  switch (props.history.location.search) {
    case "?scip":
      console.log("1");
      fileName = "Chris.pdf";
      break;

    case "?racket":
      console.log("2");
      fileName = "Racket.pdf";
      break;

    case "?pytorch":
      console.log("3");
      fileName = "DeepPyTorch.pdf";
      break;

    default:
      break;
  }

  useEffect(() => {
    // fetchPage();
    // getPdfFiles();
    getPageNo();
  }, []);

  // .post(SERVER_URL + "/api/upload", formData, {
  async function getPageNo() {
    // setLoading(true);
    await axios
      .post(SERVER_URL + "/api/books/getPageNo", {
        userId: props?.authUser?.profileId,
        storageUrl: props.history.location.state,
      })
      .then((response) => {
        // 여기서 값이 0인지 아닌지에 따라 이동 처리 해야 겠구만
        console.log(response);
        console.log(response?.data);
        console.log(response?.data?.page);
        if (response?.data?.page !== undefined) {
          // go page
          setPage(Number(response?.data?.page));
          getPageData(Number(response?.data?.page));
        } else {
          fetchData();
        }
        // setLoading(false);
      });
  }

  async function postPageNo() {
    // setLoading(true);
    await axios
      .post(SERVER_URL + "/api/books/postPageNo", {
        userId: props?.authUser?.profileId,
        storageUrl: props.history.location.state,
        page: page,
      })
      .then((response) => {
        // 여기서 값이 0인지 아닌지에 따라 이동 처리 해야 겠구만
        console.log(response);
        console.log(response?.data);
        console.log(response?.data?.page);
      });
    // setLoading(false);
  }

  async function getPdfFiles() {
    setLoading(true);
    await axios
      .post(
        ML_URL + "/getPdfFiles",
        {
          number: page,
          fileName: props.history.location.state,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url);
      });
    setLoading(false);
  }

  async function fetchData() {
    setLoading(true);
    await axios
      .post(ML_URL + "/getPdfPages", {
        number: page,
        fileName: props.history.location.state,
      })
      .then((response) => {
        clear();
        console.log(response?.data);
        setPdfText(response?.data?.text);
        setTotalPage(response?.data?.page);
        setRequestText(response?.data?.text.split("\n\n"));
        goToPdfPage(response?.data?.text.split("\n\n"));
        setLoading(false);
      });
  }

  async function getPageData(pageNo) {
    setLoading(true);
    await axios
      .post(ML_URL + "/getPdfPages", {
        number: pageNo,
        fileName: props.history.location.state,
      })
      .then((response) => {
        clear();
        console.log(response?.data);
        setPdfText(response?.data?.text);
        setTotalPage(response?.data?.page);
        setRequestText(response?.data?.text.split("\n\n"));
        goToPdfPage(response?.data?.text.split("\n\n"));
        setValue([Number(pageNo)]);
        setLoading(false);
      });
  }

  // async function fetchPage() {
  //   setLoading(true);
  //   await axios
  //     .put("https://polypage.io/getPdfPages", {
  //       number: page,
  //       fileName: props.history.location.state,
  //     })
  //     .then((response) => {
  //       console.log(response?.data);
  //       setPdfText(response?.data?.text);
  //       setRequestText(response?.data?.text.split("\n\n"));
  //       goToPdfPage(response?.data?.text.split("\n\n"));
  //     });
  //   setLoading(false);
  // }

  async function goToPdfPage(pdfText) {
    pdfText.forEach(async (element) => {
      await axios
        .post(ML_URL + "/1translator", { text: element, time: Date() })
        .then((response) => {
          console.log(response?.data);

          setTranslatedText(
            (prev) =>
              new Map([
                ...prev,
                [response?.data?.text, response?.data?.output[0]],
              ])
          );
        });
    });
  }

  function clear() {
    setRequestText([]);
    setTranslatedText(new Map());
  }

  function previousPage() {
    if (loading === true) return;
    setPage(Number(page) - 1);
    setValue([Number(page) - 1]);
    // postPageNo();
    // fetchData();
  }

  function nextPage() {
    if (loading === true) return;
    setPage(Number(page) + 1);
    setValue([Number(page) + 1]);
    // postPageNo();
    // fetchData();
  }

  const url =
    "http://localhost:8000/pdf/60cf2a58244bd810e4a70e39/Apress.Deep.Learning.with.Python.pdf";

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

  return (
    <div>
      <Container>
        <Slider
          value={value}
          step={2}
          marks
          onChange={({ value }) => {
            value && setValue(value);
            setPage(Number(value));
          }}
          onFinalChange={({ value }) => {
            // clear();
            fetchData();
            postPageNo();
          }}
          max={totalPage}
        />

        <div>
          <div>
            <Document
              file={{
                url: "http://localhost:8000/pdf/60cf2a58244bd810e4a70e39/Apress.Deep.Learning.with.Python.pdf",
                httpHeaders: {
                  "Access-Control-Allow-Methods": "*",
                  "Access-Control-Allow-Headers": "*",
                },
                withCredentials: true,
              }}
              onLoadSuccess={onDocumentLoadSuccess}
              style={{ width: 900 }}
            >
              {/* <Outline onItemClick={onItemClick} /> */}
              <Page
                pageNumber={page}
                width={Math.min(width * 0.9, 1000)} // width: 90vw; max-width: 400px
              />
            </Document>
            <p>
              1Page {page || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </button>
          </div>
        </div>
      </Container>
      <div style={{ marginTop: 20 }}></div>
      {/* {loading === true ? (
        <Loader
          style={{
            display: "flex",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
          }}
          active
        />
      ) : null} */}
      <div
        style={{
          position: "fixed",
          width: "40%",
          height: "100%",
        }}
        onClick={() => previousPage()}
      ></div>
      <div
        style={{
          position: "fixed",
          width: "40%",
          height: "100%",
          right: 0,
        }}
        onClick={() => nextPage()}
      ></div>

      <div style={{ marginBottom: 40 }}></div>
    </div>
  );
}

export default compose(
  withRouter,
  connect(
    (state) => state,
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )
)(MainView);
