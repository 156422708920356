import * as ActionTypes from "../constants/ActionTypes";

export function getProfileOne() {
  return { type: ActionTypes.GET_PROFILE_ONE };
}

export function setProfileOne(profileOne) {
  return { type: ActionTypes.SET_PROFILE_ONE, profileOne };
}

export function postProfile(post) {
  return { type: ActionTypes.POST_PROFILE, post };
}

export function postProfileStatus(status) {
  return { type: ActionTypes.POST_PROFILE_STATUS, status };
}
