import * as ActionTypes from "../constants/ActionTypes";

export function getBookList() {
  return { type: ActionTypes.GET_BOOK_LIST };
}

export function setBookList(bookList) {
  return { type: ActionTypes.SET_BOOK_LIST, bookList };
}

export function postBookRoom(post) {
  return { type: ActionTypes.POST_BOOK_ROOM, post };
}

export function postBookRoomStatus(status) {
  return { type: ActionTypes.POST_BOOK_ROOM_STATUS, status };
}
