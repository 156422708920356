import { combineEpics } from "redux-observable";

import googleLoginEpic from "./googleLoginEpic";
import getDevListEpic from "./getDevListEpic";
import getDevOneEpic from "./getDevOneEpic";
import getCookListEpic from "./getCookListEpic";
import getCookOneEpic from "./getCookOneEpic";
import getBookListEpic from "./getBookListEpic";

import postDevRoomEpic from "./postDevRoomEpic";
import postCookRoomEpic from "./postCookRoomEpic";
import postBookRoomEpic from "./postBookRoomEpic";
import postStartupRoomEpic from "./postStartupRoomEpic";

import postProfileEpic from "./postProfileEpic";
import getProfileOneEpic from "./getProfileOneEpic";

import getUserEpic from "./getUserEpic";

export default combineEpics(
  googleLoginEpic,
  getDevListEpic,
  getDevOneEpic,
  postDevRoomEpic,
  postCookRoomEpic,
  postBookRoomEpic,
  postStartupRoomEpic,
  postProfileEpic,
  getProfileOneEpic,
  getCookListEpic,
  getCookOneEpic,
  getBookListEpic,
  getUserEpic
);
