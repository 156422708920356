import React from "react";
import ReactDOM from "react-dom";
import MainView from "./component/Main/MainView";
import BookView from "./component/Book/BookView";
import BookPdfView from "./component/Book/BookPdfView";
import AppNavbar from "./component/NavigationBar/AppNavbarView";
import Welcome from "./component/welcome";

import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { Provider } from "react-redux";
import configureStore from "./redux/store/configureStore";
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { LightTheme, BaseProvider, styled } from "baseui";
import "semantic-ui-css/semantic.min.css";
import "./API/intercepter";
import { CookiesProvider } from "react-cookie";
import "katex";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
const engine = new Styletron();

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

ReactDOM.render(
  <RecoilRoot>
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <Provider store={configureStore()}>
          <CookiesProvider>
            <Router>
              <React.StrictMode>
                <AppNavbar />
                <Route exact path="/" component={MainView} />
                <Route exact path="/welcome" component={Welcome} />
                <Route exact path="/book" component={BookView} />
                <Route exact path="/pdf" component={BookPdfView} />
              </React.StrictMode>
            </Router>
          </CookiesProvider>
        </Provider>
      </BaseProvider>
    </StyletronProvider>
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
