import { combineReducers } from "redux";
import authUser from "./auth";
import loading from "./loading";
import errorMessage from "./errorMessage";
import postDevRoom from "./postDevRoom";
import postBookRoom from "./postBookRoom";
import postCookRoom from "./postCookRoom";
import postStartupRoom from "./postStartupRoom";
import devReducer from "./devReducer";
import cookReducer from "./cookReducer";
import profileReducer from "./profileReducer";
import bookReducer from "./bookReducer";
import startupReducer from "./startupReducer";
import userReducer from "./userReducer";

export default combineReducers({
  authUser,
  loading,
  devReducer,
  cookReducer,
  profileReducer,
  bookReducer,
  startupReducer,
  errorMessage,
  postDevRoom,
  postCookRoom,
  postBookRoom,
  postStartupRoom,
  userReducer,
});
