import * as ActionTypes from "../constants/ActionTypes";

export function googleLogin(header) {
  return { type: ActionTypes.GOOGLE_LOGIN, header };
}

export function setToken(header) {
  return { type: ActionTypes.SET_TOKEN, header };
}

export function getAuth(username, password) {
  return { type: ActionTypes.GET_AUTH, username, password };
}

export function setAuth(authUser) {
  return { type: ActionTypes.SET_AUTH, authUser };
}
