import * as ActionTypes from "../constants/ActionTypes";

export function getCookList() {
  return { type: ActionTypes.GET_COOK_LIST };
}

export function setCookList(cookList) {
  return { type: ActionTypes.SET_COOK_LIST, cookList };
}

export function postCookRoom(post) {
  return { type: ActionTypes.POST_COOK_ROOM, post };
}

export function postCookRoomStatus(status) {
  return { type: ActionTypes.POST_COOK_ROOM_STATUS, status };
}
