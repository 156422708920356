import * as ActionTypes from "../constants/ActionTypes";

export default function auth(state = null, action = {}) {
  switch (action.type) {
    case ActionTypes.GET_COOK_LIST:
      return null;

    case ActionTypes.SET_COOK_LIST:
      return action.cookList;

    default:
      return state;
  }
}
