export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const SET_TOKEN = "SET_TOKEN";
export const GET_AUTH = "GET_AUTH";
export const SET_AUTH = "SET_AUTH";

export const GET_DEV_LIST = "GET_DEV_LIST";
export const SET_DEV_LIST = "SET_DEV_LIST";
export const GET_DEV_ONE = "GET_DEV_ONE";
export const SET_DEV_ONE = "SET_DEV_ONE";
export const POST_DEV_ROOM = "POST_DEV_ROOM";
export const POST_DEV_ROOM_STATUS = "POST_DEV_ROOM_STATUS";

export const GET_BOOK_LIST = "GET_BOOK_LIST";
export const SET_BOOK_LIST = "SET_BOOK_LIST";
export const GET_BOOK_ONE = "GET_BOOK_ONE";
export const SET_BOOK_ONE = "SET_BOOK_ONE";
export const POST_BOOK_ROOM = "POST_BOOK_ROOM";
export const POST_BOOK_ROOM_STATUS = "POST_BOOK_ROOM_STATUS";

export const GET_COOK_LIST = "GET_COOK_LIST";
export const SET_COOK_LIST = "SET_COOK_LIST";
export const GET_COOK_ONE = "GET_COOK_ONE";
export const SET_COOK_ONE = "SET_COOK_ONE";
export const POST_COOK_ROOM = "POST_COOK_ROOM";
export const POST_COOK_ROOM_STATUS = "POST_COOK_ROOM_STATUS";

export const GET_STARTUP_LIST = "GET_STARTUP_LIST";
export const SET_STARTUP_LIST = "SET_STARTUP_LIST";
export const GET_STARTUP_ONE = "GET_STARTUP_ONE";
export const SET_STARTUP_ONE = "SET_STARTUP_ONE";
export const POST_STARTUP_ROOM = "POST_STARTUP_ROOM";
export const POST_STARTUP_ROOM_STATUS = "POST_STARTUP_ROOM_STATUS";

export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";

export const GET_PROFILE_ONE = "GET_PROFILE_ONE";
export const SET_PROFILE_ONE = "SET_PROFILE_ONE";
export const POST_PROFILE = "POST_PROFILE";
export const POST_PROFILE_STATUS = "POST_PROFILE_STATUS";
