import React from "react";
import { Card, Image as SemanticImage, Grid, Icon } from "semantic-ui-react";
import { SERVER_URL, ML_URL } from "../../config/config";
import { Button, Header } from "semantic-ui-react";
import clsx from "clsx";
import { loadCSS } from "fg-loadcss";
// import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

const numbers = [1, 2, 3, 4];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
export default function CardWelcomeView(props) {
  const classes = useStyles();
  return (
    <Grid stackable columns={4} centered>
      {numbers.map((json) => {
        return (
          <Grid.Column key={json}>
            <Card
              color="pink"
              onClick={() =>
                (window.location.href = SERVER_URL + "/auth/google")
              }
              centered
            >
              <Card.Content>
                <Icon circular inverted color="blue" name="book" />

                <SemanticImage
                  circular
                  src="https://react.semantic-ui.com/images/avatar/large/matthew.png"
                  floated="right"
                  size="mini"
                />

                <Header textAlign="right" style={{ color: "#1976D2" }} as="h4">
                  2020년 / 2월 24일 / 월요일
                </Header>
                <Card.Header textAlign="center" style={{ fontSize: 16 }}>
                  매주 월요일 진행하는 인스파이어드 독서 모임 입니다.
                </Card.Header>

                <Card.Description
                  textAlign="center"
                  style={{ fontSize: 14, marginTop: 10 }}
                >
                  이번주 주제는 제 3장 스타트업 제품/시장 궁합 찾기 입니다.
                </Card.Description>

                <Card.Meta
                  textAlign="right"
                  style={{ fontSize: 14, marginTop: 10 }}
                >
                  4명 / 6명
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <Button
                  basic
                  color="blue"
                  onClick={() =>
                    (window.location.href = SERVER_URL + "/auth/google")
                  }
                >
                  More
                </Button>
              </Card.Content>
            </Card>
          </Grid.Column>
        );
      })}
    </Grid>
  );
}
