import * as ActionTypes from "../constants/ActionTypes";

export function getStartupList() {
  return { type: ActionTypes.GET_STARTUP_LIST };
}

export function setStartupList(startupList) {
  return { type: ActionTypes.SET_STARTUP_LIST, startupList };
}

export function postStartupRoom(post) {
  return { type: ActionTypes.POST_STARTUP_ROOM, post };
}

export function postStartupRoomStatus(status) {
  return { type: ActionTypes.POST_STARTUP_ROOM_STATUS, status };
}
