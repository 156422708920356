import React, { useState, useEffect, StyleSheet, useMemo } from "react";

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { FileUploader } from "baseui/file-uploader";
// import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
// import {userJsonRecoil} from '../SignIn/api/state';
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// import { Document, Outline, Page } from "react-pdf/dist/esm/entry.webpack";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { pdfjs } from "react-pdf";
import { Drawer, SIZE, ANCHOR } from "baseui/drawer";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import * as actions from "../../redux/actions";

import { Slider } from "baseui/slider";
import { Slate, Editable, withReact } from "slate-react";

import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";

import {
  Container,
  Divider,
  Header,
  Button,
  Icon,
  Card,
  Search,
  Segment,
  Modal,
  Label,
  Loader,
  Dimmer,
  Input,
} from "semantic-ui-react";

import { Datepicker } from "baseui/datepicker";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  // ModalButton,
} from "baseui/modal";

import { useLocation } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import {
  Router,
  Route,
  IndexRoute,
  Link,
  IndexLink,
  browserHistory,
  hashHistory,
  BrowserRouter,
  Redirect,
  withRouter,
} from "react-router-dom";

import TextareaAutosize from "react-textarea-autosize";
import { SERVER_URL, ML_URL } from "../../config/config";
import TranslationView from "./TranslationView";

let page = 1;

function MainView(props) {
  const [originalText, setOriginalText] = useState("");
  const width = useWindowWidth();
  const [pdfText, setPdfText] = useState("");
  const [pdfBlob, setPdfBlob] = useState();
  const [isShowPdfView, setIsShowPdfView] = useState(false);

  const [totalPage, setTotalPage] = useState(100);
  const [value, setValue] = React.useState([0]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [requestText, setRequestText] = useState([]);
  const [translatedText, setTranslatedText] = useState(new Map());
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const [isOpen, setIsOpen] = React.useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  console.log(props);
  useEffect(() => {
    if (props.history.location.state === undefined) {
      console.log("s3url");
      props.history.push("/");
    }
    getPageNo();
  }, []);

  // .post(SERVER_URL + "/api/upload", formData, {
  async function getPageNo() {
    // setLoading(true);
    await axios
      .post(SERVER_URL + "/api/books/getPageNo", {
        userId: props?.authUser?.profileId,
        storageUrl: props.history.location.state?.["s3url"],
      })
      .then((response) => {
        console.log(response);
        setValue([Number(page)]);
        if (response?.data?.page !== undefined) {
          // go page
          console.log(response?.data?.page);
          page = Number(response?.data?.page);
          getPageData(Number(response?.data?.page));
        } else {
          fetchData();
        }
        // setLoading(false);
      });
  }

  async function postPageNo() {
    // setLoading(true);
    await axios
      .post(SERVER_URL + "/api/books/postPageNo", {
        userId: props?.authUser?.profileId,
        storageUrl: props.history.location.state?.["s3url"],
        page: page,
      })
      .then((response) => {
        // 여기서 값이 0인지 아닌지에 따라 이동 처리 해야 겠구만
        console.log(response);
        console.log(response?.data);
        console.log(response?.data?.page);
      });
    // setLoading(false);
  }

  async function showPdfViewer() {
    console.log("showPdfViewer");
    getPdfFiles();
    setIsShowPdfView(!isShowPdfView);
  }

  async function getPdfFiles() {
    setLoading(true);
    await axios
      .post(
        ML_URL + "/getPdfFiles",
        {
          number: page,
          fileName: props.history.location.state["s3url"],
          password: props.history.location.state["password"],
        },
        {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        }
      )
      .then((response) => {
        setPdfBlob(response.data);
      });
    setLoading(false);
  }

  async function fetchData() {
    if (isShowPdfView === false) {
      setLoading(true);
      await axios
        .post(ML_URL + "/getPdfPages", {
          number: page,
          fileName: props.history.location.state["s3url"],
          password: props.history.location.state["password"],
        })
        .then((response) => {
          clear();
          console.log(response?.data);
          console.log(response?.data?.number);
          setPdfText(response?.data?.text);
          setTotalPage(response?.data?.page);
          setRequestText(response?.data?.text.split("."));
          goToPdfPage(response?.data?.text.split("."));
          setLoading(false);
        });
    }
  }

  async function getPageData(pageNo) {
    if (isShowPdfView === false) {
      setLoading(true);
      await axios
        .post(ML_URL + "/getPdfPages", {
          number: pageNo,
          fileName: props.history.location.state["s3url"],
          password: props.history.location.state["password"],
        })
        .then((response) => {
          clear();
          console.log(response?.data);
          setPdfText(response?.data?.text);
          setTotalPage(response?.data?.page);
          setRequestText(response?.data?.text.split("."));
          goToPdfPage(response?.data?.text.split("."));
          setValue([Number(pageNo)]);
          setLoading(false);
        });
    }
  }

  async function goToPdfPage(pdfText) {
    if (isShowPdfView === false) {
      pdfText.forEach(async (element) => {
        await axios
          .post(
            ML_URL + "/translator",
            {
              text: element,
              time: Date(),
              target_lang: "KO", // 한국어로 번역
            },
            {
              headers: {
                "Authorization":
                  "DeepL-Auth-Key [be7a1bff-da99-4e1f-af09-626ca2d534c9:fx]", // 여기에 실제 DeepL API 키를 넣으세요
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // console.log(response?.data);

            setTranslatedText(
              (prev) =>
                new Map([
                  ...prev,
                  [response?.data?.text, response?.data?.output],
                ])
            );
          });
      });
    }
  }

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const initialValue = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  const [valueSlate, setValueSlate] = useState(initialValue);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function clear() {
    setRequestText([]);
    setTranslatedText(new Map());
  }

  function previousPage() {
    if (loading === true) return;
    page = Number(page) - 1;
    setValue([Number(page) - 1]);
    postPageNo();
    fetchData();
  }

  function nextPage() {
    if (loading === true) return;
    page = Number(page) + 1;
    setValue([Number(page) + 1]);
    postPageNo();
    fetchData();
  }

  return (
    <div>
      <Container
        style={{
          background: "#F5F6FA",
          borderRadius: 4,
          fontSize: 17,
          color: "grey",
          padding: 10,
        }}
      >
        <Slider
          value={value}
          step={1}
          marks
          onChange={({ value }) => {
            value && setValue(value);
            page = Number(value) - 1;
          }}
          onFinalChange={({ value }) => {
            // clear();
            page = Number(value);
            fetchData();
            postPageNo();
          }}
          max={totalPage}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => showPdfViewer()}
            style={{ marginBottom: 20 }}
            icon
            labelPosition="right"
          >
            {/* Pdf View | {props.history.location.state?.["s3url"]?.split("/")[1]} */}
            Pdf View
            <Icon name="caret down" />
          </Button>

          <div>
            <Button icon labelPosition="left" onClick={() => previousPage()}>
              <Icon name="angle left" />
              back
            </Button>
            <Button icon labelPosition="right" onClick={() => nextPage()}>
              next
              <Icon name="angle right" />
            </Button>
          </div>
        </div>

        <Input
          fluid
          icon="search"
          placeholder="Go to Page"
          value={page}
          onChange={(ev) => {
            page = ev.target.value;
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              console.log(event);
              console.log(page);
              setValue([Number(page)]);
              fetchData();
            }
          }}
        />
      </Container>

      <div style={{ marginTop: 20 }}></div>

      {/* <div
        style={{
          position: "fixed",
          width: "40%",
          height: "100%",
        }}
        onClick={() => previousPage()}
      ></div>
      <div
        style={{
          position: "fixed",
          width: "40%",
          height: "100%",
          right: 0,
        }}
        onClick={() => nextPage()}
      ></div> */}

      <Drawer
        isOpen={isShowPdfView}
        autoFocus
        onClose={() => setIsShowPdfView(false)}
        size={SIZE.full}
        anchor={ANCHOR.top}
      >
        <div>
          <Button icon labelPosition="left" onClick={() => previousPage()}>
            <Icon name="angle left" />
            back
          </Button>
          <Button icon labelPosition="right" onClick={() => nextPage()}>
            next
            <Icon name="angle right" />
          </Button>
        </div>

        <Document
          file={pdfBlob}
          onPassword={(callback) =>
            callback(props.history.location.state["password"])
          }
          noData={<Loader active inline="centered" />}
          loading={<Loader active inline="centered" />}
          error={<Loader active inline="centered" />}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {/* <Outline onItemClick={onItemClick} /> */}
          <Page
            pageNumber={page}
            width={Math.min(width * 0.9, 1200)} // width: 90vw; max-width: 400px
          />
        </Document>
      </Drawer>

      <Container>
        <div
          style={{
            borderRadius: 4,
            rdious: 4,
            backgroundColor: "white",
            padding: 40,
          }}
        >
          {requestText.map((element) => (
            <div
              key={uuidv4()}
              style={{
                // borderRadius: 4,
                // borderWidth: "thin",
                // borderColor: "#A9D5DE",
                // borderStyle: "solid",
                padding: 4,
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  borderRadius: 4,
                  lineHeight: 1.6,
                }}
              >
                {translatedText.get(element) === undefined ? (
                  <Loader active inline="centered" />
                ) : null}
                {translatedText.get(element)}
                <div />
                <div>
                  <p
                    style={{
                      borderRadius: 4,
                      fontSize: 14,
                      color: "darkgrey",
                      lineHeight: 1.6,
                      padding: 1,
                    }}
                  >
                    {element}
                  </p>
                </div>
              </p>

              {props.authUser?.isTranslator ? (
                <TranslationView
                  text={element}
                  userId={props?.authUser?.profileId}
                />
              ) : null}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          <div style={{ marginTop: 20 }}>
            <Button icon labelPosition="left" onClick={() => previousPage()}>
              <Icon name="angle left" />
              back
            </Button>
            <Button icon labelPosition="right" onClick={() => nextPage()}>
              next
              <Icon name="angle right" />
            </Button>
          </div>
        </div>
      </Container>
      <div style={{ height: 40 }}></div>
    </div>
  );
}

export default compose(
  withRouter,
  connect(
    (state) => state,
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )
)(MainView);
