import React, { useState, useEffect, useRef, StyleSheet } from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
// import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
// import {userJsonRecoil} from '../SignIn/api/state';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FileUploader } from "baseui/file-uploader";
import axios from "axios";
import { Grid, Cell } from "baseui/layout-grid";
import * as actions from "../../redux/actions";

import {
  Container,
  Divider,
  Header,
  Button,
  Icon,
  Card,
  Search,
  Segment,
  Modal,
  Label,
  Loader,
  Dimmer,
  Table,
  Input,
  Radio,
} from "semantic-ui-react";

import { Datepicker } from "baseui/datepicker";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  // ModalButton,
} from "baseui/modal";

import { useLocation } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import {
  Router,
  Route,
  IndexRoute,
  Link,
  IndexLink,
  browserHistory,
  hashHistory,
  BrowserRouter,
  Redirect,
  withRouter,
} from "react-router-dom";

import TextareaAutosize from "react-textarea-autosize";
import { SERVER_URL, ML_URL } from "../../config/config";

function MainView(props) {
  const [originalText, setOriginalText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [readyPdfUpload, setReadyPdfUpload] = useState(false);
  const [isTranslator, setIsTranslator] = useState(false);
  const [pdfList, setPdfList] = useState([]);
  const [pdfFile, setPdfFile] = useState(new FormData());
  const [open, setOpen] = React.useState(false);
  const [openModalPassword, setOpenModalPassword] = React.useState(false);
  const inputRef = useRef(null);
  const [passValue, setPassValue] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");

  // const notify = () => toast("Please Upload PDF file only");

  console.log(props);

  async function getPdfList() {
    setLoading(true);
    await axios
      .post(ML_URL + "/getPdfList", {
        id: props?.authUser?._id,
      })
      .then((response) => {
        console.log(response?.data);
        setPdfList(response?.data);
      });
    setLoading(false);
  }

  const token = "polypagetoken";

  function getToken(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  async function setTranslatorMode(isTranslate = false) {
    axios
      .put(SERVER_URL + "/users" + "/" + props?.authUser?._id, {
        isTranslator: isTranslate,
      })
      .then((res) => {
        setIsTranslator(isTranslate);
        console.log(res.data);
        props.actions.googleLogin({
          Authorization: "Bearer " + getToken(token).replace(/%22/g, ""),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (props.authUser === null) {
      // props.history.push("/welcome");
      props.history.push("/welcome");
    } else {
      if (props.authUser.status === "fail") {
        // props.history.push("/welcome");
        props.history.push("/welcome");
      } else {
        // props.history.push("/main");
        setIsTranslator(props.authUser?.isTranslator);
        props.history.push("/");
      }
      getPdfList();
    }
  }, []);

  async function checkLockPdf(checkLockPdf, inputPassword) {
    await axios
      .post(ML_URL + "/getPdfPages", {
        number: 0,
        fileName: selectedPdfUrl,
        password: inputPassword,
      })
      .then((response) => {
        if (response.data["text"] === "error") {
          toast("Wrong Password");
        } else {
          setOpenModalPassword(false);
          props.history.push("/book", {
            s3url: selectedPdfUrl,
            password: inputPassword,
          });
        }
      });
  }

  function uploadPdf() {
    const formData = new FormData();
    formData.append("path", props?.authUser?._id + "/");
    formData.append("file", pdfFile);
    formData.append(
      "password",
      passValue === "" ? "none!@#$%^&*()" : passValue
    );

    console.log(passValue);
    console.log(formData);
    console.log(pdfFile);

    setLoading(true);
    axios
      .post(SERVER_URL + "/api/upload", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log(res);
        getPdfList();
        setLoading(false);
        setReadyPdfUpload(false);
        setPassValue("");
      });
  }
  // useEffect(() => {

  //   return () => {

  //   }
  // }, [])

  return (
    <div>
      <div>
        {loading === true ? (
          <Loader
            style={{
              display: "flex",
              position: "fixed",
              justifyContent: "center",
              alignItems: "center",
            }}
            active
          />
        ) : null}

        <Container>
          <Divider horizontal>
            <Header as="h4">1. PDF DROP</Header>
          </Divider>
          <ToastContainer />
          <FileUploader
            // onCancel={stopFakeProgress}
            onDrop={(acceptedFiles, rejectedFiles) => {
              let file = acceptedFiles[0];

              console.log("User trying to uplaod this:", file);
              console.log(file.type);

              if (file.type === "application/pdf") {
                setReadyPdfUpload(true);
                setPdfFile(file);
              } else {
                setReadyPdfUpload(false);
                toast("Please Upload PDF file only");
                return;
              }
            }}
            // progressAmount is a number from 0 - 100 which indicates the percent of file transfer completed
            // progressAmount={progressAmount}
            // progressMessage={
            //   progressAmount
            //     ? `Uploading... ${progressAmount}% of 100%`
            //     : ''
            // }
          />

          {readyPdfUpload === true ? (
            <>
              <Divider horizontal>
                <Header as="h4">2. Upload</Header>
              </Divider>

              <Input
                action={{
                  color: "teal",
                  labelPosition: "right",
                  icon: "upload",
                  content: "Upload",
                  onClick: () => uploadPdf(),
                }}
                type="password"
                label={{ icon: "asterisk" }}
                value={passValue}
                onChange={(e, { value }) => setPassValue(value)}
                labelPosition="left corner"
                placeholder="Input Password"
              />
            </>
          ) : null}

          <Modal
            basic
            onClose={() => setOpenModalPassword(false)}
            onOpen={() => setOpenModalPassword(true)}
            open={openModalPassword}
            size="small"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Input
                action={{
                  // color: "teal",
                  // labelPosition: "right",
                  icon: "unlock",
                  // content: "",
                  onClick: () => {
                    checkLockPdf(selectedPdfUrl, inputPassword);
                  },
                }}
                type="password"
                ref={inputRef}
                value={inputPassword}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    checkLockPdf(selectedPdfUrl, inputPassword);
                  }
                }}
                onChange={(e, { value }) => {
                  setInputPassword(value);
                }}
                // labelPosition="left corner"
                placeholder="Input Password"
              />
            </div>
          </Modal>

          <Segment basic textAlign="center">
            <Divider horizontal>
              <Header as="h4">Translator Mode</Header>
            </Divider>
            <Radio
              slider
              onChange={(data, event) => setTranslatorMode(event?.checked)}
              checked={isTranslator}
            />
            {/* setTranslatorMode */}
          </Segment>

          {/* translation mode */}

          <Table celled padded striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {pdfList.map((element) => (
                <Table.Row key={element}>
                  <Table.Cell
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "inherit",
                      ":hover": { color: "inherit" },
                      ":visited": { color: "inherit" },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{ width: "96%" }}
                        onClick={() => {
                          axios
                            .post(ML_URL + "/getPdfPages", {
                              number: 0,
                              fileName: element,
                              password: "",
                            })
                            .then((response) => {
                              if (response.data["text"] === "error") {
                                console.log(element);
                                setSelectedPdfUrl(element);
                                // 비번 입력 창을 뛰우기
                                setOpenModalPassword(true);
                                inputRef.current.focus();
                              } else {
                                props.history.push("/book", {
                                  s3url: element,
                                  password: "",
                                });
                              }
                            });
                          // props.history.push("/book", element)
                        }}
                      >
                        <Icon name="book" />
                        {element.split("/")[1]}
                      </div>

                      <Modal
                        basic
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                        size="small"
                        trigger={
                          <Icon
                            color="red"
                            name="delete"
                            onClick={() => {
                              setOpen(false);
                              setSelectedPdfUrl(element);
                            }}
                          />
                        }
                      >
                        <Header icon>
                          <Icon color="red" name="delete" />
                          <p>Delete? "{selectedPdfUrl.split("/")[1]}" </p>
                        </Header>
                        <Modal.Content>
                          <p>Permanently delete a file.</p>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            basic
                            color="green"
                            inverted
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <Icon name="remove" /> No
                          </Button>
                          <Button
                            color="red"
                            inverted
                            onClick={() => {
                              axios
                                .delete(SERVER_URL + "/api/upload", {
                                  data: {
                                    fileName: selectedPdfUrl,
                                  },
                                })
                                .then((response) => {
                                  console.log(response?.data);
                                  setOpen(false);
                                  getPdfList();
                                });
                            }}
                          >
                            <Icon name="checkmark" /> Yes
                          </Button>
                        </Modal.Actions>
                      </Modal>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>
      </div>
      <div style={{ height: 40 }}> </div>
    </div>
  );
}

export default compose(
  withRouter,
  connect(
    (state) => state,
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )
)(MainView);
