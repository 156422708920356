import { mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import * as ActionTypes from "../constants/ActionTypes";
import * as actions from "../actions";
import { ajax } from "rxjs/ajax";
import token from "../../API/AjaxGetToken";
import { SERVER_URL, ML_URL } from "../../config/config";

export const postStudyRoomEpic = action$ =>
  action$.pipe(
    ofType(ActionTypes.POST_COOK_ROOM),
    mergeMap(action =>
      ajax
        .post(SERVER_URL + "/api/cook", action.post, Object.assign({}, token()))
        .pipe(mergeMap(response => [actions.postCookRoomStatus(response)]))
    )
  );

export default postStudyRoomEpic;
