import { mergeMap } from "rxjs/operators";

import { ofType } from "redux-observable";
import * as ActionTypes from "../constants/ActionTypes";
import * as actions from "../actions";
import { ajax } from "rxjs/ajax";
import { SERVER_URL, ML_URL } from "../../config/config";

export const Epic = (action$) =>
  action$.pipe(
    ofType(ActionTypes.GET_DEV_LIST),
    mergeMap((action) =>
      ajax
        .getJSON(SERVER_URL + "/api/dev", Object.assign({}, action.header))
        .pipe(mergeMap((response) => [actions.setDevList(response)]))
    )
  );

export default Epic;
