import { mergeMap } from "rxjs/operators";

import { ofType } from "redux-observable";
import * as ActionTypes from "../constants/ActionTypes";
import * as actions from "../actions";
import { ajax } from "rxjs/ajax";
import { SERVER_URL, ML_URL } from "../../config/config";

export const exampleEpic = (action$) =>
  action$.pipe(
    ofType(ActionTypes.GOOGLE_LOGIN),
    mergeMap((action) =>
      ajax
        .getJSON(
          SERVER_URL + "/api/getUserProfileWithToken",
          Object.assign({}, action.header)
        )
        .pipe(mergeMap((response) => [actions.setAuth(response)]))
    )
  );

export default exampleEpic;

// export default function authEpic(action$, store) {
//   return action$.pipe(
//     ofType(ActionTypes.GET_AUTH),
//     switchMap(action =>
//       from(fakeAuth(action.username, action.password)).pipe(
//         mergeMap(auth => [actions.setAuth(auth), actions.hideErrorMessage()]),
//         startWith(actions.loadingStart()),
//         catchError(err => [actions.showErrorMessage(err.message)]),
//         concat([actions.loadingEnd()])
//       )
//     )
//   );
// }

// export const exampleEpic = action$ =>
//   action$.pipe(
//     ofType(ActionTypes.GET_AUTH),
//     mergeMap(action =>
//       ajax
//         .getJSON("https://jsonplaceholder.typicode.com/todos/1")
//         .pipe(map(response => actions.setAuth(response)))
//     )
//   );

// export const exampleEpic = action$ =>
//   action$.pipe(
//     ofType(ActionTypes.GET_AUTH),
//     mergeMap(action =>
//       from(axios.get("https://jsonplaceholder.typicode.com/todos/1"))
//         .map(response => fetchData(response))
//         .catch(error => Observable.ofType(FETCH_DATA_FAIL))
//     )
//   );

// ajax({ method: "DELETE", url, body, headers });

// ajax.getJSON(`http://localhost:3001/search/${action.payload.query}`)
//     .pipe(map(response => ({ type: FETCH_SEARCH_FULFILLED, response })
