const token = "polypagetoken";

function getToken(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function AjaxGetToken() {
  console.log("checkLogin");
  if (document.cookie.includes(token)) {
    let jwt = getToken(token).replace(/%22/g, "");
    console.log(jwt);
    let config = {
      Authorization: "Bearer " + jwt,
    };
    return config;
  }
}

export default AjaxGetToken;
