import { makeStyles } from "@material-ui/core/styles";
import {
  Router,
  Switch,
  Route,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";

import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import { loadCSS } from "fg-loadcss";
// import WriteStudyView from "../PostDevStudyView/PostDevStudyView";
import { useSelector, useDispatch } from "react-redux";
import { Icon as Icon2, Header } from "semantic-ui-react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as actions from "../../redux/actions";
import { SERVER_URL } from "../../config/config";
import { StyledLink } from "baseui/link";
import { useStyletron } from "baseui";
import axios from "axios";
import { AppNavBar, setItemActive } from "baseui/app-nav-bar";
import { Blank } from "baseui/icon";

function renderItem(item) {
  return item.label;
}

const MAIN_NAV = [
  // {
  //   path: "developer",
  //   item: { label: "Developer" },
  //   mapItemToNode: renderItem,
  //   mapItemToString: renderItem,
  // },
  // {
  //   path: "cook",
  //   item: { label: "Cook" },
  //   mapItemToNode: renderItem,
  //   mapItemToString: renderItem,
  // },
  {
    path: "book",
    item: { label: "MyBooks" },
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  },
];

const USER_NAV = [
  {
    path: "Me",
    item: { label: "Me" },
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  },
  {
    path: "LogOut",
    item: { label: "Log Out" },
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  },
];

const G_NAV = [
  {
    path: "/auth/google",
    item: { label: "Login Please" },
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const token = "polypagetoken";

function getToken(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function checkLogin(props) {
  console.log("checkLogin");
  if (document.cookie.includes(token)) {
    let jwt = getToken(token).replace(/%22/g, "");
    console.log(jwt);
    let config = {
      Authorization: "Bearer " + jwt,
    };

    console.log(props);

    if (props.authUser === null) {
      console.log("googleLogin");
      props.actions.googleLogin(config);
    } else {
      if (props.authUser.status === "fail") {
        props.history.push("/welcome");
      } else {
        props.history.push("/");
      }
    }
  } else {
    // window.location.href = "/welcome";
    // props.history.push("/welcome");
    // console.log("go welcome");
  }
}

function AppNavbarView(props, { children }) {
  const [email, setEmail] = useState();

  const [mainItems, setMainItems] = React.useState([
    {
      icon: Blank,
      path: "book?scip",
      label: "Scip",
    },
    {
      icon: Blank,
      path: "book?racket",
      label: "Racket",
    },
    {
      icon: Blank,
      path: "book?pytorch",
      label: "PyTorch",
    },
    // {
    //   active:
    //     window.location.pathname.includes("/developer") === true ? true : false,
    //   icon: Blank,
    //   path: "developer",
    //   label: "Developer",
    // },
    // {
    //   active:
    //     window.location.pathname.includes("/book") === true ? true : false,
    //   icon: Blank,
    //   path: "book",
    //   label: "My Books",
    // },
  ]);

  const getUserProfile = async (props) => {
    console.log(props);
    const response = await axios.get(
      SERVER_URL + "/users/" + props?.authUser._id
    );
    setEmail(response.data.email);
  };

  if (props.authUser !== null) {
    getUserProfile(props);
  }

  // console.log(props.authUser);
  // console.log(props.authUser?._id); 5d5564889c6a012540781dd6

  console.log("App Nav Bar");

  const history = useHistory();

  useEffect(() => {
    checkLogin(props);
    return history.listen((location) => {
      // setMainItems([
      //   // {
      //   //   active:
      //   //     location.pathname.includes("/developer") === true ? true : false,
      //   //   icon: Blank,
      //   //   path: "developer",
      //   //   label: "Developer",
      //   // },
      //   {
      //     active: location.pathname.includes("/book") === true ? true : false,
      //     icon: Blank,
      //     path: "book",
      //     label: "My Books",
      //   },
      // ]);
    });
  }, [history]);

  const [isOpen, setIsOpen] = React.useState(false);

  function close() {
    setIsOpen(false);
  }

  function removeCookie(name, domain) {
    document.cookie =
      name +
      "=; Path=/; Domain=" +
      domain +
      "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  return (
    <React.Fragment>
      <AppNavBar
        title={
          <StyledLink
            $style={{
              cursor: "pointer",
              textDecoration: "none",
              color: "inherit",
              ":hover": { color: "inherit" },
              ":visited": { color: "inherit" },
            }}
            onClick={() => props.history.push("/")}
          >
            {/* <Header as="h1">polypage</Header> */}
            <Header as="h2">polypage.ai</Header>
          </StyledLink>
        }
        // mainItems={mainItems}
        onMainItemSelect={(item) => {
          if (item.label === undefined) return;

          switch (item.path) {
            case "/users/auth/google":
              window.location.href = SERVER_URL + "/users/auth/google?web=true";
              break;

            case "book":
              {
                props.history.push("/book");
              }
              break;

            case "developer":
              {
                props.history.push("/developer");
              }
              break;

            case "LogOut":
              {
                removeCookie("polypagetoken", ".polypage.ai");
                window.location.href = "/";
              }
              break;
            default:
              props.history.push("/" + item.path, props.authUser);
          }
          setMainItems((prev) => setItemActive(prev, item));
        }}
        userItems={
          props.authUser !== null
            ? [
                // {
                //   icon: Blank,
                //   path: "MyStudy",
                //   label: "Study List",
                // },
                // {
                //   icon: Blank,
                //   path: "MyProfile",
                //   label: "View Profile",
                // },
                { icon: Blank, path: "LogOut", label: "Log Out" },
              ]
            : [
                {
                  icon: Blank,
                  path: "/users/auth/google?web=true",
                  label: "Please Login",
                },
              ]
        }
        // mainNav={props.authUser !== null ? MAIN_NAV : []}
        onUserItemSelect={(item) => {
          if (item.label === undefined) return;

          switch (item.path) {
            case "/users/auth/google?web=true":
              window.location.href = SERVER_URL + "/users/auth/google?web=true";
              break;

            case "MyProfile":
              {
                window.location.href =
                  window.location.origin + "/MyProfile/" + props.authUser?._id;
              }
              break;

            case "LogOut":
              {
                const token = "polypagetoken";
                document.cookie =
                  token + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                document.cookie =
                  token + ".sig" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                window.location.href = "/";
              }
              break;
            default:
              props.history.push("/" + item.path, props.authUser);
          }

          // {
          //   item.path === "/users/auth/google"
          //     ? (window.location.href = SERVER_URL + "/users/auth/google")
          //     : props.history.push("/" + item.path, props.authUser);
          // }
        }}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              outline: `black thin`,
              backgroundColor: "#efe6df",
            }),
          },
        }}
        // userNav={props.authUser !== null ? USER_NAV : G_NAV}
        username={props.authUser !== null ? email : "G"}
        usernameSubtitle={props.authUser !== null ? "email" : "not login"}
        userImgUrl="https://image.flaticon.com/icons/svg/2111/2111477.svg"
      />
      <div style={{ marginTop: "16px" }} />
    </React.Fragment>
  );
}

export default compose(
  withRouter,
  connect(
    (state) => state,
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )
)(AppNavbarView);

// export default AppNavbar;

// <div>
//   <HeaderNavigation style={{ height: 60 }}>
//     <StyledNavigationList $align={ALIGN.left}>
//       <StyledNavigationItem>
//         <H5
//           style={{ cursor: "pointer" }}
//           onClick={() => props.history.push("/")}
//         >
//           Meetlive.io
//         </H5>
//       </StyledNavigationItem>
//     </StyledNavigationList>

//     <StyledNavigationList $align={ALIGN.center} />

//     <StyledNavigationList $align={ALIGN.right}>
//       {props.authUser !== null ? (
//         <>
//           <StyledNavigationItem>
//             <StyledLink
//               style={{ cursor: "pointer" }}
//               onClick={() =>
//                 props.history.push("/developer", props.authUser)
//               }
//             >
//               Developer
//             </StyledLink>
//           </StyledNavigationItem>
//           <StyledNavigationItem>
//             <StyledLink
//               style={{ cursor: "pointer" }}
//               onClick={() => props.history.push("/cook", props.authUser)}
//             >
//               Cooking
//             </StyledLink>
//           </StyledNavigationItem>
//           <StyledNavigationItem>
//             <StyledLink
//               style={{ cursor: "pointer" }}
//               onClick={() => props.history.push("/book", props.authUser)}
//             >
//               Book
//             </StyledLink>
//           </StyledNavigationItem>
//           {/* <StyledNavigationItem>
//         <StyledLink href="/startup">Startup</StyledLink>
//       </StyledNavigationItem> */}
//         </>
//       ) : null}

//       {props.authUser !== null ? (
//         props.authUser.status === "fail" ? (
//           <StyledNavigationItem>
//             <Button
//               onClick={() =>
//                 (window.location.href = SERVER_URL + "/users/auth/google")
//               }
//               kind={KIND.tertiary}
//             >
//               <Icon
//                 className={clsx(classes.icon, "fab fa-google-plus")}
//                 fontSize="large"
//               />
//             </Button>
//           </StyledNavigationItem>
//         ) : (
//           <StyledNavigationItem>
//             <Button
//               onClick={() => props.history.push("/me", props.authUser)}
//               kind={KIND.tertiary}
//             >
//               <Icon size="large" name="user circle" />
//             </Button>
//           </StyledNavigationItem>
//         )
//       ) : (
//         <StyledNavigationItem>
//           <Button
//             onClick={() =>
//               (window.location.href = SERVER_URL + "/users/auth/google")
//             }
//             kind={KIND.tertiary}
//           >
//             <Icon size="large" name="google plus circle" />
//           </Button>
//         </StyledNavigationItem>
//       )}
//     </StyledNavigationList>
//   </HeaderNavigation>
//   {children}

//   <div style={{ marginTop: "8px" }} />
// </div>
// authUser;
// export default Posts;

// const Registration = props => {
//   console.log(props);
//   return (
//     <div className="Registration">
//       <p>some text</p>
//     </div>
//   );
// };

// export default Registration;
