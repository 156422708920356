import { mergeMap } from "rxjs/operators";

import { ofType } from "redux-observable";
import * as ActionTypes from "../constants/ActionTypes";
import * as actions from "../actions";
import { ajax } from "rxjs/ajax";
import { SERVER_URL, ML_URL } from "../../config/config";

export const exampleEpic = (action$) =>
  action$.pipe(
    ofType(ActionTypes.GET_DEV_ONE),
    mergeMap((action) =>
      ajax
        .getJSON(
          SERVER_URL + "/api/dev/" + action.id,
          Object.assign({}, action.header)
        )
        .pipe(mergeMap((response) => [actions.setDevOne(response)]))
    )
  );

export default exampleEpic;
